export const b2bBenefits = [
  {
    id: 1,
    tabTitle: "Drive Better Business Decisions",
    headline: "Drive Better Business Decisions",
    description:
      "Boost your revenue, reduce costs, or achieve your sustainability goals. Leverage AI-powered insights and predictive models to explore endless possibilities in your marketplace operations, ensuring optimal outcomes every time.",
    image: "/assets/images/customer-1.png",
  },
  {
    id: 2,
    tabTitle: "Accelerate Collaboration and Innovation",
    headline: "Accelerate Collaboration and Innovation",
    description:
      "Move beyond traditional methods. Transition to a digital marketplace where your data, teams, and partners converge. Collaborate in real-time, visualize trends, and run multiple scenarios to make faster, smarter decisions.",
    image: "/assets/images/customer-2.png",
  },
  {
    id: 3,
    tabTitle: "AI-Powered Marketplace Intelligence",
    headline: "AI-Powered Marketplace Intelligence",
    description:
      "Secure the best deals with instant, real-time pricing insights from a vast network of suppliers. Simplify procurement processes with automated workflows, ensuring you get the most value with minimal effort.",
    image: "/assets/images/salesforce-3.png",
  },
];
