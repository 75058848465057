import { setLanguage } from "config/language";
import React, { useEffect, useState } from "react";
import BazAINavigation from "../common/BazAINavigation";
import BannerArea from "./BannerArea";
import Partners from "./Partners";
import FooterV2 from "../common/FooterV2";
import BazAIHelmet from "../common/BazAIHelmet";
import { baseUrl } from "config/apiUrl";
import BazAiScreenShot from "./BazAiScreenShot";
import ProductFacilites from "../common/ProductFacilities";
import PricePlan from "../common/PricePlan";
import ExperiencesAdvantage from "./ExperiencesAdvantage";
import CustomerImpact from "./CustomerImpact";

export default function LandingHomePage() {
	const [scrolling, setScrolling] = useState(false);
	const [scrollTop, setScrollTop] = useState(0);
	useEffect(() => {
		function onScroll() {
			let currentPosition = document.documentElement.scrollTop;
			if (currentPosition > 150) {
				setScrolling(true);
			} else {
				setScrolling(false);
			}
			setScrollTop(currentPosition);
		}

		window.addEventListener("scroll", onScroll);
		return () => window.removeEventListener("scroll", onScroll);
	}, [scrollTop]);

	if (localStorage.getItem("lan") === null) {
		setLanguage();
	}
	const procData = [
		{
			id: 1,
			extraLabel: "Unlock the Future of Procurement",
			userType: "Unlock the Future of Procurement",
			description: `<b>Transformative Automation</b>
Wave goodbye to tedious manual tasks. Baz AI automates up to 90% of procurement workflows, allowing your team to focus on high-impact strategic initiatives.`,
			banner: "/assets/images/products/supplier1.png",
		},

		{
			id: 2,
			extraLabel: "Our Solutions",
			userType: "Your Strategic Procurement Partner",
			description: `<b>Centralized Intelligence</b>
Harness Generative AI powered Intelligence for all supplier interactions and documents, ensuring unparalleled visibility and control across your entire procurement process.`,
			description2: `<b>Intelligent Workflow Automation</b> From sourcing and onboarding to purchase orders and invoice processing, our AI agents handle it all—optimizing efficiency and reducing errors at every step.`,
			banner: "/assets/images/products/supplier3.png",
		},
	];

	return (
		<>
			<BazAIHelmet
				title='EkkBaz AI'
				description='Transcending Your Procurement Teams With Limitless AI Agents.  '
				image='/assets/images/LogoAI.png'
				url={`${baseUrl.webUrl}/`}
			/>
			<BazAINavigation scrolling={"isScrolling"} from='mainLanding' />
			<BannerArea />
			<BazAiScreenShot />
			{/* <ProductFacilites facilities={procData} from='food' parsing={true} /> */}

			<ExperiencesAdvantage />
			<div className='container mt-12'>
				<div className='w-full lg:p-5 p-2 text-center'>
					<h6 className='font-bold text-[#000000] lg:text-[28px] text-20 lg:pb-3 pb-0 pt-3 lg:pt-1 leading-10'>
						Built for Your Success
					</h6>
					<p
						className={`font-medium lg:text-20 text-14  text-[#00000080] text-center`}>
						<b>
							Baz AI is more than just a tool; it's your dedicated
							partner.
						</b>
					</p>
					<p
						className={`mt-2 text-center font-medium lg:text-22 text-14 text-[#00000080]`}>
						Our AI agents work collaboratively with your team, ensuring
						complete transparency and control while adapting to your
						unique business needs.
					</p>
				</div>
				<div className='w-full lg:p-5 p-2 text-center'>
					<h6 className='font-bold text-[#000000] lg:text-[28px] text-20 lg:pb-3 pb-0 pt-3 lg:pt-1 leading-10'>
						Ready to Elevate Your Procurement Strategy?
					</h6>
					<p
						className={`font-medium lg:text-20 text-14  text-[#00000080]  text-left text-center`}>
						<b>Join the Baz AI movement today</b>
					</p>

					<div>
						<a
							href='https://outlook.office365.com/owa/calendar/AIAgentforBusiness@EkkBaz.com/bookings/'
							// style={{ border: "1px solid rgba(0,0,0,.30)" }}
							target='_blank'
							rel='noreferrer'
							className={`text-center rounded-[10px] px-5 text-18 bg-[#009061] text-white  py-[18px]  font-bold w-auto mt-10 inline-block`}>
							Schedule Free Demo
						</a>
					</div>
				</div>
			</div>
			{/* <PricePlan from='food' /> */}
			<FooterV2 />
		</>
	);
}
