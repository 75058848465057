import React from "react";
import { useSelector } from "react-redux";

export default function BannerContent() {
  const ekkLanguage = useSelector((state) => state.lan);

  return (
    <div className="pt-[200px] lg:pt-[230px] lg:pb-[50px] pb-0">
      <h1
        className={`lg:text-50 md:text-40 text-24  font-extrabold text-262626-1000 text-center flex flex-wrap justify-center`}
      >
        {ekkLanguage?.lan.landing.header1}
      </h1>
      <h1
        className={`lg:text-50 md:text-40 text-24  font-extrabold text-262626-1000 text-center flex flex-wrap justify-center`}
      >
        {ekkLanguage?.lan.landing.header2}
      </h1>
    </div>
  );
}
