import React from "react";
import SingleProductFacility from "./SingleProductFacility";

export default function ProductFacilites({ facilities, from }) {
	return (
		<div className='lg:pt-16 pt-12 container'>
			{facilities.map((facility) => {
				return (
					<SingleProductFacility
						key={facility?.id}
						id={facility?.id}
						userType={facility?.userType}
						extraLabel={facility?.extraLabel}
						description={facility?.description}
						formLink={facility?.formLink}
						btnLabel={facility?.btnLabel}
						banner={facility?.banner}
						from={from}
					/>
				);
			})}
		</div>
	);
}
