import { setLanguage } from "config/language";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import BazAINavigation from "../common/BazAINavigation";
import BannerArea from "./BannerArea";
import Blog from "./Blog";
import CustomerImpact from "./CustomerImpact";
import Partners from "./Partners";
import FooterV2 from "../common/FooterV2";
import BazAIHelmet from "../common/BazAIHelmet";
import { baseUrl } from "config/apiUrl";

export default function LandingHomePage() {
  const [scrolling, setScrolling] = useState(false);
  const [scrollTop, setScrollTop] = useState(0);
  useEffect(() => {
    function onScroll() {
      let currentPosition = document.documentElement.scrollTop;
      if (currentPosition > 150) {
        setScrolling(true);
      } else {
        setScrolling(false);
      }
      setScrollTop(currentPosition);
    }

    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, [scrollTop]);

  if (localStorage.getItem("lan") === null) {
    setLanguage();
  }
  return (
    <>
      <BazAIHelmet
        title="EkkBaz AI"
        description="Transforming businesses with AI Agents."
        image="/assets/images/LogoAI.png"
        url={`${baseUrl.webUrl}/`}
      />
      <BazAINavigation
        scrolling={scrolling ? "isScrolling" : ""}
        from="mainLanding"
      />
      <BannerArea />
      <div className="lg:mt-10">
        <Blog />
      </div>
      <CustomerImpact />
      <Partners />
      <FooterV2 />
    </>
  );
}
