export const deliveryBenefits = [
  {
    id: 1,
    tabTitle: "Seamless Delivery Management",
    headline: "Seamless Delivery Management",
    description:
      "Assign drivers for timely deliveries to customer addresses with options like cash on delivery and pick-and-pay.",
    image: "/assets/images/delivery-1.png",
  },
  {
    id: 2,
    tabTitle: "Real Time Order Management",
    headline: "Real Time Order Management",
    description:
      "Track orders in real time at each stage to ensure efficiency and enhance customer satisfaction.",
    image: "/assets/images/delivery-2.png",
  },
  {
    id: 3,
    tabTitle: "AI-Driven Delivery Optimization",
    headline: "AI-Driven Delivery Optimization",
    description:
      "Leverage AI to optimize delivery routes and predict delivery times, reducing costs and improving efficiency. AI-driven insights help anticipate potential delays and streamline logistics operations, ensuring smooth and timely deliveries.",
    image: "/assets/images/salesforce-3.png",
  },
];
