import React, { useLayoutEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import LandingForFilm from "pages/Landing/Film";
import LandingForHealth from "pages/Landing/Health";
import LandingForElectronics from "pages/Landing/Electronics";
import LandingForFood from "pages/Landing/Food";
import ContactUsPage from "pages/Landing/ContactUs";
import AboutUsPage from "pages/Landing/AboutUs";
import LandingHomePage from "pages/Landing/Home";
import BlogPostPage from "pages/Landing/Home/BlogPostPage";
import SingleBlogPage from "pages/Landing/Home/SingleBlogPage";
import ErrorPage from "pages/Landing/Error";
import RefundPolicy from "pages/Landing/Policies/RefundPolicy";
import PrivacyPolicy from "pages/Landing/Policies/PrivacyPolicy";
import TermsCondition from "pages/Landing/Policies/TermsCondition";
import LandingForHotel from "pages/Landing/Hotel";
import LandingForCustomerManagement from "pages/Landing/CustomerManagement";
import LandingForDeliveryManagement from "pages/Landing/DeliveryManagement";
import LandingForDistributorManagement from "pages/Landing/DistributorManagement";
import LandingForInventoryManagement from "pages/Landing/InventoryManagement";
import LandingForSaleForceManagement from "pages/Landing/SalesForceManagement";
import LandingForWareHouseManagement from "pages/Landing/WareHouseManagement";
import LandingForARAutomation from "pages/Landing/ARAutomation";
import LandingForB2BMarketplace from "pages/Landing/B2BMarketplace";
import LandingForEmbeddedFinancing from "pages/Landing/EmbeddedFinancing";
import LandingForSupplierManagement from "pages/Landing/SupplierManagement";

function Mobile() {
	const pathname = useLocation();
	useLayoutEffect(() => {
		window.scrollTo(0, 0);
	}, [pathname]);
	return (
		<Routes>
			<Route path='/' element={<LandingHomePage />} />
			<Route path='/post/en' element={<BlogPostPage />} />
			<Route path='/post/en/:id' element={<SingleBlogPage />} />
			<Route path='/company/about-us' element={<AboutUsPage />} />
			<Route path='/company/contact-us' element={<ContactUsPage />} />
			{/* Industries  */}
			<Route path='/industry/film' element={<LandingForFilm />} />
			<Route path='/industry/healthcare' element={<LandingForHealth />} />
			<Route path='/industry/hotel' element={<LandingForHotel />} />
			<Route
				path='/industry/electronics'
				element={<LandingForElectronics />}
			/>
			<Route path='/industry/c-goods' element={<LandingForFood />} />
			{/* Products */}
			<Route
				path='/product/ar-automation'
				element={<LandingForARAutomation />}
			/>
			<Route path='/product/b2b' element={<LandingForB2BMarketplace />} />
			<Route
				path='/product/customer'
				element={<LandingForCustomerManagement />}
			/>
			<Route
				path='/product/delivery'
				element={<LandingForDeliveryManagement />}
			/>
			<Route
				path='/product/distributor'
				element={<LandingForDistributorManagement />}
			/>
			<Route
				path='/product/financing'
				element={<LandingForEmbeddedFinancing />}
			/>
			<Route
				path='/product/inventory'
				element={<LandingForInventoryManagement />}
			/>
			<Route
				path='/product/salesforce'
				element={<LandingForSaleForceManagement />}
			/>
			<Route
				path='/product/supplier'
				element={<LandingForSupplierManagement />}
			/>
			<Route
				path='/product/warehouse'
				element={<LandingForWareHouseManagement />}
			/>
			{/* Policies */}
			<Route path='/refund-policy' element={<RefundPolicy />} />
			<Route path='/privacy-policy' element={<PrivacyPolicy />} />
			<Route path='/terms-conditions' element={<TermsCondition />} />
			<Route path='*' element={<ErrorPage />} />
		</Routes>
	);
}

export default Mobile;
