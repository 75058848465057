export const distributorBenefits = [
  {
    id: 1,
    tabTitle: "Streamline Distribution Process",
    headline: "Streamline Distribution Process",
    description:
      "Optimize your distribution operations with comprehensive tools designed for efficiency. Automate product listings, assign roles for delivery and collection, and track orders in real-time to ensure timely fulfillment. Streamlined distribution processes not only improve operational efficiency but also attract more orders from distributors.",
    image: "/assets/images/distributor-1.png",
  },
  {
    id: 2,
    tabTitle: "Connect with a Vast Network of Distributors",
    headline: "Connect with a Vast Network of Distributors",
    description:
      "Expand your reach by connecting with a large number of distributors, enhancing collaboration and business opportunities. Our platform enables seamless communication and partnership, ensuring your products reach the right markets. Build stronger relationships and increase your order volume with an extensive distribution network.",
    image: "/assets/images/distributor-2.png",
  },
  {
    id: 3,
    tabTitle: "Leverage AI-Driven Features for Success",
    headline: "Leverage AI-Driven Features for Success",
    description:
      "Harness AI to elevate operational efficiency and make informed decisions. Our AI-driven functionalities offer valuable insights for optimizing supply chains and strategic planning, keeping your business competitive and primed for sustained growth.",
    image: "/assets/images/salesforce-3.png",
  },
];
