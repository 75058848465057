export const financingBenefits = [
  {
    id: 1,
    tabTitle: "Seamless Payment Solutions",
    headline: "Seamless Payment Solutions",
    description:
      "Enable your customers to choose from various payment methods, ensuring a smooth and flexible transaction experience. Our platform supports multiple options to cater to their preferences and enhance satisfaction.",
    image: "/assets/images/create-order-payment.jpg",
  },
  {
    id: 2,
    tabTitle: "Customer Financing Support",
    headline: "Customer Financing Support",
    description:
      "Offer customers tailored financing solutions that meet their needs, helping them manage their purchases with ease. Boost their buying power and increase your sales by providing flexible financing options.",
    image: "/assets/images/create-order.jpg",
  },
  {
    id: 3,
    tabTitle: "AI-Driven Loyalty Rewards",
    headline: "AI-Driven Loyalty Rewards",
    description:
      "Utilize AI to offer personalized service fee discounts to loyal customers, boosting retention and encouraging repeat business.",
    image: "/assets/images/salesforce-3.png",
  },
];
