import { setLanguage } from "config/language";
import Banner from "../common/Banner";
import BazAINavigation from "../common/BazAINavigation";
import { useEffect, useState } from "react";
import FooterV2 from "../common/FooterV2";
import BazAIHelmet from "../common/BazAIHelmet";
import { baseUrl } from "config/apiUrl";
import { deliveryBenefits } from "./deliveryBenefits";
import Benefits from "../common/Benefits";

export default function LandingForDeliveryManagement() {
  const [scrolling, setScrolling] = useState(false);
  const [scrollTop, setScrollTop] = useState(0);

  useEffect(() => {
    function onScroll() {
      const currentPosition = document.documentElement.scrollTop;
      setScrolling(currentPosition > 150);
      setScrollTop(currentPosition);
    }

    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, [scrollTop]);

  if (localStorage.getItem("lan") === null) {
    setLanguage();
  }

  return (
    <>
      <BazAIHelmet
        title="Delivery Management"
        description="Seamless Delivery Management with AI Agent."
        image="/assets/images/LogoAI.png"
        url={`${baseUrl.webUrl}/delivery`}
      />
      <BazAINavigation
        scrolling={scrolling ? "isScrolling" : ""}
        from="mainLanding"
      />
      <div className="container">
        <Banner
          headline="Hotel"
          bannerImage="/assets/images/delivery-banner.jpeg"
          from="health"
        />
      </div>
      <Benefits benefits={deliveryBenefits} />
      <FooterV2 />
    </>
  );
}
