import { setLanguage } from "config/language";
import Banner from "../common/Banner";
import BazAINavigation from "../common/BazAINavigation";
import { useEffect, useState } from "react";
import FooterV2 from "../common/FooterV2";
import BazAIHelmet from "../common/BazAIHelmet";
import { baseUrl } from "config/apiUrl";
import Benefits from "../common/Benefits";
import { b2bBenefits } from "./b2bBenefits";

export default function LandingForB2BMarketplace() {
  const [scrolling, setScrolling] = useState(false);
  const [scrollTop, setScrollTop] = useState(0);

  useEffect(() => {
    function onScroll() {
      const currentPosition = document.documentElement.scrollTop;
      setScrolling(currentPosition > 150);
      setScrollTop(currentPosition);
    }

    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, [scrollTop]);

  if (localStorage.getItem("lan") === null) {
    setLanguage();
  }

  return (
    <>
      <BazAIHelmet
        title="B2B Marketplace"
        description="Handle your B2B Marketplace with AI Agent."
        image="/assets/images/LogoAI.png"
        url={`${baseUrl.webUrl}/b2b`}
      />
      <BazAINavigation
        scrolling={scrolling ? "isScrolling" : ""}
        from="mainLanding"
      />
      <div className="container">
        <Banner
          headline="Hotel"
          bannerImage="/assets/images/b2b_banner.jpg"
          from="health"
        />
      </div>
      <Benefits benefits={b2bBenefits} />
      <FooterV2 />
    </>
  );
}
